import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Product from "../components/Product";
import CourseBanner from "../components/CourseBanner";
const Unshaped = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const OpticalData = {
    data: [
      {
        img: "./images/wall-hangings/img1.jpg",
        name: "Wall Hangings",
        manufactring: "hand tufted- wool",
        area: "Hand Made Natural Jute",
        price: 475,
      },
      {
        img: "./images/wall-hangings/img2.jpg",
        name: "Wall Hangings",
        manufactring: "hand tufted- wool",
        area: "Hand Made Natural Jute",
        price: 475,
      },
      {
        img: "./images/wall-hangings/img3.jpg",
        name: "Wall Hangings",
        manufactring: "hand tufted- wool",
        area: "Hand Made Natural Jute",
        price: 475,
      },
      {
        img: "./images/wall-hangings/img4.jpg",
        name: "Wall Hangings",
        manufactring: "hand tufted- wool",
        area: "Hand Made Natural Jute",
        price: 475,
      },
      {
        img: "./images/wall-hangings/img5.jpg",
        name: "Wall Hangings",
        manufactring: "hand tufted- wool",
        area: "Hand Made Natural Jute",
        price: 475,
      },
      {
        img: "./images/wall-hangings/img6.jpg",
        name: "Wall Hangings",
        manufactring: "hand tufted- wool",
        area: "Hand Made Natural Jute",
        price: 475,
      },
      {
        img: "./images/wall-hangings/img7.jpg",
        name: "Wall Hangings",
        manufactring: "hand tufted- wool",
        area: "Hand Made Natural Jute",
        price: 475,
      },
      {
        img: "./images/wall-hangings/img8.jpg",
        name: "Wall Hangings",
        manufactring: "hand tufted- wool",
        area: "Hand Made Natural Jute",
        price: 475,
      },
      {
        img: "./images/wall-hangings/img9.jpg",
        name: "Wall Hangings",
        manufactring: "hand tufted- wool",
        area: "Hand Made Natural Jute",
        price: 475,
      },
      {
        img: "./images/wall-hangings/img10.jpg",
        name: "Wall Hangings",
        manufactring: "hand tufted- wool",
        area: "Hand Made Natural Jute",
        price: 475,
      },
      {
        img: "./images/wall-hangings/img11.jpg",
        name: "Wall Hangings",
        manufactring: "hand tufted- wool",
        area: "Hand Made Natural Jute",
        price: 475,
      },
      {
        img: "./images/wall-hangings/img12.jpg",
        name: "Wall Hangings",
        manufactring: "hand tufted- wool",
        area: "Hand Made Natural Jute",
        price: 475,
      },
    ],
  };

  const bannerData = {
    image: "/images/wall-hangings/banner.jpg",
    heading: "Wall-Hanging Rugs: Transform Your Walls into Art",
    heading2: "Lorem ipsum dolor sit amet consectetur adipisicing.",
    para: " Wall-hanging rugs are a unique and versatile way to add personality and style to any room. These stunning textiles, woven with intricate patterns and vibrant colors, can transform blank walls into captivating focal points.",
  };
  return (
    <div className="page-content">
  
      <CourseBanner {...bannerData} />
      <section className="bg2 product-container-wrapper " id="optical-showroom">
        <div className="container py-5">
          
          <Product ProductData={OpticalData} name="Optical Showroom Design" />
        </div>
      </section>
    </div>
  );
};

export default Unshaped;
