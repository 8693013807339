import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Product from "../components/Product";
import CourseBanner from "../components/CourseBanner";
const Transitional = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    const { hash } = location;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const OpticalData = {
    data: [
      {
        img: "./images/carpets/img1.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img2.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img3.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img4.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img5.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img6.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img7.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img8.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img9.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img10.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img11.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img12.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img13.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img14.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img15.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img16.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img17.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img18.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img19.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img20.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img21.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img22.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img23.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      {
        img: "./images/carpets/img24.jpg",
        name: "Carpets and Rugs",
        manufactring: "hand tufted- wool",
        area: "Hand Knotted Natural Wool",
        price: 475,
      },
      
    ],
  };

  const bannerData = {
    image: "/images/transitional-banner.jpg",
    heading: "Comfort & Style: Carpets & Rugs for Every Room",
    heading2: "Lorem ipsum dolor sit amet consectetur adipisicing.",
    para: "Carpets and rugs offer more than just visual appeal; they provide warmth, comfort, and sound absorption to any space. Whether you're looking to add a touch of luxury to your living room, define a cozy reading nook, or protect your floors from wear and tear, our collection of carpets and rugs has something for everyone.",
  };
  return (
    <div className="page-content">
     
      <CourseBanner {...bannerData} />
      <section className="bg2 product-container-wrapper " id="optical-showroom">
        <div className="container py-5">
         
          <Product ProductData={OpticalData} name="Optical Showroom Design" />
        </div>
      </section>
    </div>
  );
};

export default Transitional;
